import Socket from "../Socket/Socket";

class User {

    static getProps(props) {
        this.props = props;
    }

    static storage() {
        return localStorage;
    }

    static token() {
        return this.storage().getItem('userToken');
    }

    static removeToken() {
        this.storage().removeItem('userToken');
    }

    static setToken(token) {
        this.storage().setItem('userToken', token)
    }

    static getStorageItem(name) {
        return this.storage().getItem(name);
    }

    static setStorageItem(name, item) {
        this.storage().setItem(name, item)
    }
    static checkValidateToken(token) {
        return new Promise((resolve, reject) => {
            Socket.checkValidateToken(token)
                .then(res => {
                    if (res === 'token expire') {
                        this.removeToken();
                    }
                    resolve(res)
                })
        })
    }

    static forgotPassword(email) {
        return new Promise((resolve, reject) => {
            Socket.forgotPassword(email)
                .then(res => {
                    if (res === 'success') {
                    } else {
                        reject(res)
                    }
                });
        })
    }
    static createPassword(email) {
        return new Promise((resolve, reject) => {
            Socket.createPassword(email)
                .then(res => {
                    if (res === 'success') {
                        resolve(res)
                    } else {
                        reject(res)
                    }
                });
        })
    }

    static resetPassword(token, password) {
        return new Promise((resolve, reject) => {
            Socket.resetPassword(token, password)
                .then(res => {
                    if (res === 'success') {
                        resolve(res)
                    } else {
                        reject(res)
                    }
                })
        })
    }

    static resetPasswordVanko(token, lastpassword, verifypsw, newPwd) {
        return new Promise((resolve, reject) => {
            Socket.resetPasswordVanko(token, lastpassword, verifypsw, newPwd)
                .then(res => {
                    if (res === 'success') {
                        resolve(res)
                    } else {
                        reject(res)
                    }
                })
        })
    }

    static checkIsAdmin() {
        return new Promise(resolve => {
            Socket.isAdmin(this.token())
                .then(res => {
                    resolve(res)
                })
        })
    }


    static userInfo() {
        return new Promise(resolve => {
            Socket.getUserInfo(this.token())
                .then(res => {
                    resolve(res);
                })
        })
    }

    static authError(user) {
        let error = false;
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let [key, value] of Object.entries(user)) {
            if (value === '') {
                error = true;
            }
            if (key === 'email') {
                if (!regex.test(value)) {
                    error = true;
                }
            }
        }
        return error;
    }

    static login(user) {
        return new Promise((resolve, reject) => {
            if (this.authError(user)) {

                reject("L'identifiant doit être une adresse email !")
            } else {
                Socket.loginUser(user)
                    .then(res => {
                        if (res === 'User not found') {
                            res = 'Identifiant incorrect !'
                            reject(res);
                        }
                        else if (res === 'Bad password') {
                            res = 'Mot de passe incorrect !'
                            reject(res);
                        }
                        resolve(res)
                    })
                    .catch(err => console.error("User.login " + err))
            }
        })
    }

    static register(user) {
        return new Promise((resolve, reject) => {
            if (this.authError(user)) {
                reject('error field')
            } else {
                Socket.createUser(user)
                    .then(res => {
                        resolve(res)
                    })
            }
        })
    }

    static leaveAllRooms() {
        Socket.leaveAllRooms();
    }
}

export default User;