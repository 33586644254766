import React from 'react';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
// @ts-ignore
import Wanup from '../../assets/images/Layout/WANUP-LOGO.svg';
// @ts-ignore
import Rfiber from '../../assets/images/Login/2/Jpg_png_svg/Rfiber-logo.svg';
// @ts-ignore
import Spirit from '../../assets/images/Login/2/Jpg_png_svg/Spirit-logo.svg';
// @ts-ignore
import Athena from '../../assets/images/Home/athena.png';
// @ts-ignore
import Sophia from '../../assets/images/Home/sophia.png';
import User from "../../Utils/User/User";
import { TransitionWanup, SpiritTransition, Video } from "../../store/action/navBarAction";
import Socket, { socket } from '../../Utils/Socket/Socket';
import { connect, ConnectedProps } from "react-redux";
import "./Portails.scss";


const mapStateToProps = (state: any) => {
    return {
        data: state.CollectionsReducer,
        users: state.NavbarReducer.user,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        // setUserinfo: (data) => dispatch(userInfo(data)),
        TransitionWanup: (bool: boolean) => dispatch(TransitionWanup(bool)),
        SpiritTransition: (bool: boolean) => dispatch(SpiritTransition(bool)),
        video: (bool: boolean) => dispatch(Video(bool)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector> & RouteComponentProps<any>

interface Props extends PropsFromRedux {

}

interface State {
    data: Array<any>
}

class Portails extends React.PureComponent<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: []
        }
    }



    async componentDidMount() {

        document.title = "Sayse | Portail";

        let user = await User.userInfo();
        Socket.read('Roles', { _id: user.role_id })
            .then(res => {
                this.setState({ data: res.data[0].service })
            })
            .catch(error => console.error(error))

    }

    // cursor = e => {
    //         const cursor = document.getElementById("arm");
    //         const menu = document.getElementById("menu-container");
    //         const x = e.clientX;
    //         const y = e.clientY;
    //         const sizeCursor = cursor.getBoundingClientRect();
    //         const calcHeight = window.innerHeight - menu.clientHeight;

    //         if (menu) {
    //             if (cursor) {
    //                 if (y> calcHeight / 2) {

    //                 }
    //                 else{
    //                     return false;
    //                 }
    //                 if (x > window.innerWidth / 2) {
    //                     cursor.classList.replace("left", "right");
    //                     cursor.style.top = y + 5 + "px";
    //                     cursor.style.left = x - 75 +"px";
    //                 }
    //                 else{
    //                     cursor.classList.replace("right", "left");

    //                     cursor.style.top = y + 5 + "px";
    //                     cursor.style.left = x - (sizeCursor.width -75) +"px";
    //                 }
    //             }
    //     }
    // }

    // setLogConnection = async () => {
    //     let user = await UserComponent.userInfo()
    //     let linkEntityUser = this.props.data.EntityUser.filter(element => element.user_uuid === user._id).map(element => element.entity_uuid);
    //     const log = {
    //         createdAt: new Date(),
    //         login: user.email,
    //         ipAdress: localIpUrl(),
    //         user_agent: navigator.userAgent,
    //         role: user.profile,
    //         entity_uuid: linkEntityUser,
    //     };

    //     Socket.insert('Connectedlogs', log.entity_uuid, log);
    // }

    wanup = () => {
        this.props.video(true)
        this.props.TransitionWanup(true)
        setTimeout(() => {
            window.location.replace(`${process.env.REACT_APP_REDIRECTION_WANUP}?token=${User.token()}`)
            // window.location.replace(`https://app.wanup.io/Dashboard?token=${User.token()}`)
        }, 5000);
    }

    Spirit = () => {
        this.props.video(true)
        this.props.SpiritTransition(true)
        setTimeout(() => {
            // window.location.replace(`http://localhost:3001/Dashboard?token=${User.token()}`)
            window.location.replace(`https://tool-it.sayse.fr/Dashboard?token=${User.token()}`)
        }, 5000);

    }


    render() {
        const { data } = this.state
        return (
            <div className="home-menu">
                <div id="menu-container">
                    <div className="top-menu">
                        <ul className="first-raw">
                            {
                                data[0] && data[0].wanup === true &&
                                <li className="wanup">
                                    <div className="link-background"></div>
                                    <div id="wanup" className="link-content"
                                        onClick={() => this.wanup()}
                                    >
                                        {/* <Link to="/dashboard"> */}
                                        <img src={Wanup} alt="wanup logo" />
                                        {/* </Link> */}
                                    </div>
                                </li>
                            }
                            {/* {
                                data[0] && data[0].Sophia === true &&
                                <li className="sophia">
                                    <div className="link-background"></div>
                                    <div id="sophia" className="">
                                        <a href="#">
                                            <img src={Sophia} alt="sophia logo" />
                                        </a>
                                    </div>
                                </li>
                            } */}
                            {/* <li className="rfiber">
                                <div className="link-background"></div>
                                <div id="rfiber" className="">
                                    <a href="#">
                                        <img src={Rfiber} alt="rfiber logo" />
                                    </a>
                                </div>
                            </li> */}
                        </ul>
                        <ul className="second-raw">
                            {
                                data[0] && data[0].Spirit === true &&
                                <li className="spirit">
                                    <div className="link-background"></div>
                                    <div id="spirit" className=""
                                        onClick={() => this.Spirit()} >
                                        <img src={Spirit} alt="toolit logo" />
                                    </div>
                                </li>
                            }
                            {/* {
                                data[0] && data[0].Athena === true &&
                                <li className="athena">
                                    <div className="link-background"></div>
                                    <div id="athena" className="">
                                        <a href="#">
                                            <img src={Athena} alt="athena logo" />
                                        </a>
                                    </div>
                                </li>
                            } */}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default connector(Portails);                                                          